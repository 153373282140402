@import 'colors';

.call-to-action-button {
    color: $color_on_primary;
    background-color: $primary;
    font-family: 'Roboto Condensed', sans-serif;
    position: fixed;
    width: 100%;
    height: 52px;
    font-size: 16px;
    align-content: center;

    //Animation
    //&::after {
    //  content: "\f054";
    //  font-weight: 400;
    //  position: absolute;
    //  left: 85%;
    //  top: 31%;
    //  right: 5%;
    //  bottom: 0;
    //  opacity: 0;
    //
    //}

    &:hover {
        // background: $primary;
        //  transition: all 0.5s;
        // border-radius: 10px;
        //  box-shadow: 0px 6px 15px $primary_variant;
        //  border-bottom: none;
        span {
            text-decoration: underline;
        }

        // animation: jelly 4s;
    }
}

.bottom-button-card {
    width: 100%;
    bottom: 0;
    z-index: 3;
    position: sticky !important;
    color: $primary;
}

.action-button-underline {
    font-family: 'Roboto Regular', sans-serif !important;
    text-decoration: underline !important;
    color: $primary;
    background: transparent;
    padding: 0 !important;
}

.back-button {
    margin: 15px 0 0 15px;
    width: 22px;
    height: 22px;
}

.secondary-call-to-action-button {
    background-color: unset;
    color: $primary;
    padding: 0 !important;
    min-width: 0 !important;
}

.secondary-call-to-action-button-subscribe {
    background-color: #bfbfbf;
    color: #000000;
    padding: 0 !important;
    min-width: 0 !important;
    height: 55px;
}

.border-button {
    border: 1px solid;
    width: 113px;
    height: 35px;
    font-family: 'Roboto Condensed';
    background: none;
    font-size: 12px;
}

.border-button-mobile {
    border: 1px solid black;
    width: 89px;
    height: 30px !important;
    text-align: center;
    font-size: 12px;
    font-family: 'Roboto Condensed';
    background: none;
    color: black;
}
.secondary-filter-button {
    background-position: center;
    transition: background 0.8s;
    border: none;
    border-radius: 4px;
    background-color: white;
    line-height: 36px;
    font-size: 16px;
    cursor: pointer;
    color: #20a87e;
    outline: none;
    text-decoration: underline;
}
.secondary-filter-button:hover {
    background: #f7f7f7 radial-gradient(circle, transparent 1%, #f7f7f7 1%)
        center/15000%;
}
.secondary-filter-button:active {
    background-color: #20a87d4a;
    background-size: 100%;
    transition: background 0s;
}

//@keyframes jelly {
//  25% {
//    transform: scale(0.9, 1.1);
//  }
//
//  50% {
//    transform: scale(1.1, 0.9);
//  }
//
//  75% {
//    transform: scale(0.95, 1.05);
//  }
//}

// Animated CTA
//.call-to-action-button {
// // ::ng-deep .mat-button-wrapper {
//    cursor: pointer;
//    display: inline-block;
//    position: relative;
//    transition: 0.5s !important;
////  }
//}
//
//.call-to-action-button :after {
////  ::ng-deep .mat-button-wrapper:after {
//    content: 'fxcgf';
//    position: absolute;
//    opacity: 0;
//    top: 0;
//    right: -20px;
//    transition: 0.5s !important;
// // }
//}
//
//  .call-to-action-button :hover {
//   // ::ng-deep .mat-raised-button :hover {
//      padding-right: 25px;
//    content: ' \003E';
//  //  position: absolute;
//  //  opacity: 0;
//    top: 0;
//    right: -20px;
//    transition: 0.5s !important;
//   // }
//  }
//
//  .call-to-action-button :hover :after {
//   // ::ng-deep .mat-button-wrapper:after {
//      opacity: 1;
//      right: 0;
//   // }
//  }

.flat-no-border-black-button {
    width: 225px;
    height: 35px;
    background-color: black;
    border: 0px;
    box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    outline: none;
    transition: 0.2s all;
}

.flat-no-border-black-button:active {
    transform: scale(0.98);
    /* Scaling button to 0.98 to its original size */
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    /* Lowering the shadow */
}

.button-with-round-corners {
    display: flex;
    padding: 12px 24px;
    border-radius: 4px;
    border: 0;
    justify-content: center;
    align-items: center;
    background-color: $primary;
    color: $color_on_primary;
    text-align: center;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 13px;
    font-style: normal;
    line-height: 120%; /*15.6px */
    cursor: pointer;
}
